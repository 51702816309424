var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Items',{attrs:{"navigation":"client-order-archives","useServerPagination":true,"canChangeDisplay":false,"canCreate":false,"hideActions":true,"headers":[            
        { text: 'courierOrderNumber', value: 'courierOrderNumber' },
        { text: 'requestedOn', value: 'requestedOn' },
        { text: 'respondedOn', value: 'respondedOn' }],"displayFilters":['requestedOn', 'respondedOn']},scopedSlots:_vm._u([{key:"startedOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateAndTime")(item.startedOn != null ? item.startedOn : null))+" ")]}},{key:"endedOn",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateAndTime")(item.endedOn != null ? item.endedOn : null))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }